"use client";
import Script from "next/script";
import { analyticsGrouping, handleClick } from "../../lib/gtag";
import { useUserData } from "../hooks/useUserData";
import { useEffect, useRef } from "react";
import { usePathname } from "@/navigation";

const HOTJAR_ID = process.env.NEXT_PUBLIC_HOTJAR_ID;
const FB_ID = process.env.NEXT_PUBLIC_FB_ID;

export function Analytics() {
  const { data: user, isLoading, isError } = useUserData();
  const pathname = usePathname(); // Get the current route
  const savedPathNameRef = useRef(pathname); // Keep track of the last tracked path
  const previousUserRef = useRef(null); // Keep track of the previous user

  useEffect(() => {
    if (isLoading || isError || !user) return;

    // Trigger pageview if the route changes
    if (savedPathNameRef.current !== pathname) {
      const contentGroup = analyticsGrouping(pathname);
      const previousUser = previousUserRef.current;

      if (
        !previousUser ||
        previousUser.id !== user?.id ||
        previousUser.isLoggedIn !== user?.isLoggedIn ||
        previousUser.isSubscribed !== user?.isSubscribed ||
        previousUser.profile?.childDob !== user?.profile?.childDob ||
        previousUser.profile?.neighborhood !== user?.profile?.neighborhood
      ) {
        // @ts-ignore
        window.gtag("set", {
          id: `${user?.id ?? ""}`,
          isLoggedIn: `${user?.isLoggedIn}`,
          isSubscribed: `${user?.isSubscribed}`,
          dob: user?.profile?.childDob?.slice(0, 7) ?? "",
          neighborhood: user?.profile?.neighborhood ?? "",
        });

        previousUserRef.current = user;
      }
      (window as any).gtag("event", "page_view", {
        page_path: pathname,
        content_group: contentGroup,
      });
      savedPathNameRef.current = pathname;
    }
  }, [pathname, isLoading, isError, user]);

  useEffect(() => {
    if (isLoading || isError || user.isAdmin) return;
    const rootElement = document.querySelector("body");
    rootElement?.addEventListener("click", handleClick);

    return () => {
      rootElement?.removeEventListener("click", handleClick);
    };
  }, [isLoading, isError, user]);

  if (isLoading || isError || !user) return null;

  const GA_ID =
    user.isAdmin && process.env.NODE_ENV === "production"
      ? ""
      : process.env.NEXT_PUBLIC_GA_ID;

  /**
   * Render analytics script tags
   */
  return (
    <>
      {/* Google Analytics Script */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID}`}
      />
      <Script
        id="google-tag--code"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            window.gtag("set", {
              id: "${user?.id ?? ""}",
              isLoggedIn: ${user?.isLoggedIn ? "true" : "false"}, // Defaults to "false" for unauthenticated users
              isSubscribed: ${user?.isSubscribed ? "true" : "false"},
              dob: "${user?.profile?.childDob?.slice(0, 7) ?? ""}",
              neighborhood: "${user?.profile?.neighborhood ?? ""}",
            });
            gtag('config', '${GA_ID}', {
              page_path: window.location.pathname,
              content_group: analyticsGrouping(window.location.pathname),
            });

            function analyticsGrouping(path) {
              if (path.includes("/kitas/") || path.endsWith("/kitas")) return "kitas";
              if (path.includes("/events/") || path.endsWith("/events")) return "events";
              if (path.includes("/blog/") || path.endsWith("/blog")) return "blog";
              if (path.includes("/timeline/") || path.endsWith("/timeline")) return "timeline";
              if (path.includes("/perks/") || path.endsWith("/perks")) return "perks";
              if (path.includes("/guides/") || path.endsWith("/guides")) return "guides";
              return "other";
            }
          `,
        }}
      />
      {HOTJAR_ID && (
        <Script id="hotjar" strategy="afterInteractive">
          {`
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:${HOTJAR_ID},hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
        </Script>
      )}
      {FB_ID && (
        <Script id="facebook" strategy="afterInteractive">
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${FB_ID}');
          fbq('track', 'PageView');
        `}
        </Script>
      )}
    </>
  );
}
