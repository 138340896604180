import { configureStore } from "@reduxjs/toolkit";
import categoryReducer from "./reducers/categorySlice";
import newsletterModalReducer from "./reducers/newsletterModalSlice";

const store = configureStore({
  reducer: {
    category: categoryReducer,
    newsletterModal: newsletterModalReducer,
  },
});
export const makeStore = () => {
  return configureStore({
    reducer: {
      category: categoryReducer,
      newsletterModal: newsletterModalReducer,
    },
  });
};

export default store;
export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
